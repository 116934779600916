<template>
  <div class="home">
    <header>
      <m-page-header title="邮件设置" />
    </header>
    <main class="main">
      <div class="main_header">
        <div class="main_left">
          <div class="left_content">
            <el-image style="width: 100%; height: 100%" :src="url" fit="cover"></el-image>
          </div>
        </div>
        <div>
          <h2>降价通知邮件</h2>
          <p>
            <a
              target="_blank"
              :href="`${api}/pricecutsremind-config-editemail.html?plugin_token=${state.token}`"
              ><el-button type="primary">可视化编辑</el-button></a
            >
            <a
              target="_blank"
              :href="`${api}/pricecutsremind-config-viewemail.html?plugin_token=${state.token}`"
            >
              <el-button> <i class="giftcard-iconfont icon-newform-preview"></i> 预览</el-button></a
            >
          </p>
        </div>
      </div>
      <div class="main_from">
        <p>设置</p>
        <div class="form">
          <el-form
            size="medium"
            label-position="left"
            ref="ruleForm"
            :model="formJson"
            :rules="rules"
          >
            <div class="from">
              <el-form-item label="邮件标题" prop="email_title">
                <el-input v-model="formJson.email_title"></el-input>
                <span class="tooltip">
                  <el-tooltip
                    content="变量说明:{price_cuts}为降价信息，直接降价为金额，商品特价为折扣，批发为价格区间，秒杀为折扣。{product_name}为商品名称"
                    placement="right"
                    effect="light"
                  >
                    <i class="el-icon-question" style="font-size: 16px; margin-left: 5px"></i>
                  </el-tooltip>
                </span>
              </el-form-item>
              <el-form-item label="发件人昵称" prop="email_name">
                <el-input v-model="formJson.email_name"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </main>
    <div class="save">
      <el-button type="primary" :loading="loading" @click="save">提交</el-button>
    </div>
  </div>
</template>

<script>
import { saveemail, getdetail } from '../api/MailSetting';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import mailcover from '../statics/img/mailcover.png';
import { api } from '../../../tools/config';
export default {
  name: 'MailSeeting',
  setup() {
    const formJson = ref({
      email_title: '', //邮件标题
      email_name: '', //发件人昵称
    });
    const { state } = useStore();
    const url = ref(mailcover);
    const loading = ref(false);
    const rules = ref({
      email_title: [
        { required: true, message: '请输入邮件标题', trigger: 'blur', whitespace: true },
      ],
      email_name: [
        { required: true, message: '请输入发件人昵称', trigger: 'blur', whitespace: true },
      ],
    });
    const ruleForm = ref();
    async function getConfing() {
      const { data } = await getdetail();
      formJson.value.email_title = data.email_title;
      formJson.value.email_name = data.email_name;
    }
    function save() {
      ruleForm.value.validate(valid => {
        if (valid) {
          loading.value = true;
          saveemail(formJson.value)
            .then(() => {
              loading.value = false;
              ElMessage.success({
                message: '操作成功',
                type: 'success',
              });
              //getConfing();
            })
            .catch(() => {
              loading.value = false;
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
    return {
      formJson,
      loading,
      rules,
      url,
      api: api(),
      state,
      ruleForm,
      save,
      getConfing,
    };
  },
  mounted() {
    this.getConfing();
  },
};
</script>

<style scoped lang="scss">
@import '../../giftcard/statics/scss/iconfont';
.main {
  padding: 20px;
  .main_header {
    margin: 20px 0;
    display: flex;
    align-items: center;
    > div {
      flex: 1;
      a {
        display: inline-block;
        margin-right: 16px;
      }
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      button {
        min-width: 136px;
      }
      button:last-child {
        border: 1px solid #dcdfe6;
      }
      .time {
        font-size: 14px;
        color: #999999;
        padding: 30px 0;
      }
    }
    .main_left {
      flex: 0 0 514px;
      margin-right: 94px;
      .left_content {
        height: 300px;
        padding: 12px;
        border: 1px solid #d7dde3;
        box-sizing: border-box;
      }
    }
  }
  .main_from {
    > p {
      padding-bottom: 15px;
    }
    .form {
      background-color: #f6f6f6;
      padding: 15px;
      box-sizing: border-box;
      .tooltip {
        position: absolute;
        top: 0px;
        left: 70px;
      }
    }

    .el-form-item {
      max-width: 450px;
    }
  }
}
.save {
  padding: 10px 20px;
  background-color: #fafafa;
  button {
    min-width: 180px;
  }
}
</style>
