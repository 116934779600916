// 公用api
import request from '@/tools/request';

// 邮件设置
export function saveemail(data) {
  return request({
    url: `/pricecutsremind-config-saveemail.html`,
    method: 'post',
    data,
  });
}
// 获取配置详情
export function getdetail(data) {
  return request({
    url: `/pricecutsremind-config-detail.html`,
    method: 'get',
    params: data,
  });
}
